import React from "react"
import XButtonIcon from "../svg/x-button.inline.svg"
import { useTranslation } from "react-i18next"



const GlossarySideNavSection = ({ props, changeItem }) => {
  const {t} = useTranslation();
  const closeSideSection = ()=>{
    changeItem(null)
  }

  return (
      <div id="side-nav-glossary" className="sidenav-glossary">
						<div className="side-nav-glossary-wrapper">
						{/* CLOSE BUTTON */}
							<div className="closebtn icon_button" onClick={closeSideSection}>
								<XButtonIcon className="x-button"/>
								<div className="menu_title right closebtn">{t('main.sideNav.close')}</div>
							</div>
							{/* INFO */}
							<div className="side-nav-glossary-info">
								<div className="glossary-info-item info-item1">{props.name}</div>
								<div className="glossary-info-item info-item2">
                  {props.explanation}
								</div>

							</div>
						</div>
			</div>
  )
}

export default GlossarySideNavSection
