import React, { useEffect, useState } from "react"
import MenuIcon from "../svg/menu-left.inline.svg"
import MenuIconDark from "../svg/menu-left-black.inline.svg"
import { openNav } from "../scripts/sideNavMenu.js"
import useDocumentScrollThrottled from "../scripts/useDocumentScrollThrottled"
import IndexSearchSection from "./user_profile_sections/IndexSearchSection"
import UserLoginName from "./UserLoginName"
import SearchIconWhite from "../svg/search-white.inline.svg"
import { useTranslation } from "react-i18next"
import { getHeaderClasses } from "../scripts/Utils"
import ChangeChoiceLanguage from "../components/ChangeChoiceLanguage"
import { navigate } from "gatsby"

const IndexHeaderSection = (props) => {
  const { t, i18n } = useTranslation()

  const [shouldBeActive, setShouldBeActive] = useState(false)

  useDocumentScrollThrottled(callbackData => {
    const { currentScrollTop } = callbackData

    setShouldBeActive(currentScrollTop > 50)
  })
  const { active, shrinked, dark_menu, triggered, shrink } = getHeaderClasses(
    shouldBeActive
  )

  const [, setPathname] = useState("")

  const [allSelectTypes, setAllSelectTypes] = useState({
    searchInput: [],
  })

  useEffect(() => {
    setPathname(window.location.pathname)
  }, [])

  function handleFormSubmit(e) {
    e.preventDefault()
    navigate(
      `/${i18n.language}/result-map-page?searchInput=${encodeURIComponent(
        allSelectTypes.searchInput.toString()
      )}`
    )
  }

  const handleChange = e => {
    const { name, value } = e.target

    setAllSelectTypes(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }
  return (
    <div className="header-wrapper">
      {/* HEADER */}
      <div id="header" className={`header container py-4 px-5 ${shrinked}`}>
        <div className="row d-flex justify-content-between">
          <div className="logo-menu-block f-ac f-js col-md-5 col-lg-3">
            {/* LOGO */}
            <div className={`logo_full mR-20 ${shrink}`}>
              <div className="logo_full_block logo_full_block_1">Smart</div>
              <div className="logo_full_block logo_full_block_2">
                Admissions
              </div>
              <div className="logo_full_block logo_full_block_3">Service</div>
              <div className="logo_shrinked_block">
                S&nbsp;&nbsp;&nbsp;A&nbsp;&nbsp;&nbsp;S
              </div>
            </div>
            {/* MENU BUTTON */}
            <div className={`icon_button menu ${triggered}`} onClick={openNav}>
              <MenuIcon className={`menu_icon ${dark_menu}`} />
              <MenuIconDark
                className={`menu_icon shrinked-menu-icon ${dark_menu}`}
              />

              <div className={`menu_title right ${active}`}>
                {t("school.header.menu")}
              </div>
            </div>
          </div>
          <div className="col login-panel f-ac f-je">
            <UserLoginName pathName={dark_menu} />
            <ChangeChoiceLanguage isActive={shouldBeActive} />
          </div>
        </div>
      </div>
      {/* SEARCH */}
      <div id="first-entry-block"></div>
      <div className="f-jc container">
        <div className="search-wrapper f-js">
          {/* TITLE */}

          <div className="col">
            <div className="search_title">
              <div className="remove-text-indent">{t("main.header.find1")}</div>


              <div className="remove-text-indent">{t("main.header.find2")}</div>

            </div>
          </div>
          {/* SEARCH BLOCK */}

          <IndexSearchSection
            arrayTypes={props.arrayTypes}
            arrayPrograms={props.arrayPrograms}
            arrayLocations={props.arrayLocations}
            arraySummerTypes={props.arraySummerTypes}
            arraySummerPrograms={props.arraySummerPrograms}
            arraySummerLocations={props.arraySummerLocations}
          />
        </div>
      </div>
    </div>
  )
}

export default IndexHeaderSection
