import ArrowRightIconRed from "../svg/arrow-right-red.inline.svg"

import LockerIcon from "../svg/lock.inline.svg"

import { useTranslation } from "react-i18next"

import { Link } from "gatsby"

import React from "react"

import { Swiper, SwiperSlide } from "swiper/react"

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper"

// import "swiper/swiper.scss"
// import "swiper/components/navigation/navigation.scss"
// import "swiper/components/pagination/pagination.scss"
// import "swiper/components/scrollbar/scrollbar.scss"

import { useCurrentUser } from "../components/CurrentUserContext"
import { openNavUser } from "../scripts/loginMenus"

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

const IndexHandbooksSection = ({ articleGroups }) => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const { currentUser } = useCurrentUser()

  const changeName = item => {
    const numberSlice = Number(item.toString().slice(-1))

    if (item === 1 && lang === "en") {
      return true
    }
    if (numberSlice === 1 && (lang === "ru" || lang === "uk")) {
      return true
    }
    if (
      numberSlice === 2 ||
      numberSlice === 3 ||
      (numberSlice === 4 &&
        (lang === "ru" || (lang === "uk" && (lang === "ru" || lang === "uk"))))
    ) {
      return false
    }

    return false
  }
  const changeNameSelect = item => {
    const numberSlice = Number(item.toString().slice(-1))
    if (
      numberSlice === 5 ||
      numberSlice === 6 ||
      numberSlice === 7 ||
      numberSlice === 8 ||
      numberSlice === 9 ||
      (numberSlice === 0 && (lang === "ru" || lang === "uk"))
    ) {
      return true
    }
    return false
  }

  const individualCasesName = numberSlice => {
    if (
      (numberSlice >= 11 && numberSlice <= 14) ||
      (numberSlice >= 111 && numberSlice <= 114) ||
      (numberSlice >= 211 && numberSlice <= 214) ||
      (numberSlice >= 311 &&
        numberSlice <= 314 &&
        (lang === "ru" || lang === "uk"))
    ) {
      return true
    }
    return false
  }

  const openNavSection = () => {
    if (!currentUser) {
      openNavUser()
    }
  }
  const openNavUserSelect = item => {
    if (!item && !currentUser) {
      openNavUser()
    }
  }

  const isPublicCard = item => {
    if (item || currentUser) {
      return "cart-info-active"
    } else {
      return "cart-info-notActive"
    }
  }

  const changeSelectedLetters = item => {
    if (individualCasesName(item.articlesNumber) && lang !== "en") {
      return t("main.whiteBlock.articleSelect")
    } else {
      if (changeNameSelect(item.articlesNumber)) {
        return t("main.whiteBlock.articleSelect")
      } else {
        if (changeName(item.articlesNumber)) {
          return t("main.whiteBlock.article")
        } else {
          return t("main.whiteBlock.articles")
        }
      }
    }
  }

  const scrollSelect = id => {
    const violation = document.getElementById(id)
    window.scrollTo({
      top: violation?.offsetTop,
      behavior: "smooth",
    })
  }

  const resolveArticlePublicity = article => {
    if (article.isPublic) {
      return (
        <Link to={`/${lang}/article-groups/${article.resolvedPath}`}>
          <ArrowRightIconRed className="arrow_right-red" />
        </Link>
      )
    } else {
      if (currentUser) {
        return (
          <Link to={`/${lang}/article-groups/${article.resolvedPath}`}>
            <ArrowRightIconRed className="arrow_right-red" />
          </Link>
        )
      } else {
        return (
          <div className="f-js f-ac">
            <LockerIcon />
            <div className="hand-book-slider-cart-info-login">
              {t("main.whiteBlock.log")}
            </div>
          </div>
        )
      }
    }
  }

  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  const myPagination = React.useRef(null)

  return (
    <div>
      <div className="white-block mt-10 pb-7">
        {/* CREATE ACCOUNT CART */}
        <div className="container">
          <div className="row experts-help-wrapper">
            <div className="create-account-cart curs-P col-12">
              <div className="create-account-cart-info py-6 px-6 w-100">
                <div className="create-account-cart-info-title">
                  {t("main.whiteBlock.miss")}
                </div>
                <div className="create-account-cart-info-body">
                  {t("main.whiteBlock.exp")}
                </div>
                <div className="f-js curs-P mt-6">
                  <div
                    className="create-account-cart-info-link"
                    onClick={() => scrollSelect("our-cons")}
                  >
                    {t("main.whiteBlock.createAcc")}{" "}
                    <ArrowRightIconRed className="arrow_right-red" />
                  </div>
                  <div className="arrow_right-red"></div>
                </div>
              </div>
              <div className="create-account-cart-image col-lg-4"></div>
            </div>
          </div>

          {/* HAND BOOK */}
          <div id="handbooks" className="hand-book-wrapper">
            <div className="row">
              <div className="hand-book-header-title col-12">
                <div>{t("main.whiteBlock.handbooks")}</div>
                <div className="hand-book-header-title-description">
                  {t("main.whiteBlock.decision")}
                </div>
              </div>
            </div>
            {/* HAND BOOK SLIDER */}
            <div className="hand-book-slider row">
              {/* <div
                ref={navigationNextRef}
                className="hand-book-slider-control-button slider-right"
              />
              <div
                ref={navigationPrevRef}
                className="hand-book-slider-control-button slider-left"
              /> */}
              <Swiper
                // slidesPerView={3}
                // spaceBetween={180}
                grabCursor={true}
                className="mySwiper"
                navigation={{
                  nextEl: ".hand-book-slider-control-button.slider-right",
                  prevEl: ".hand-book-slider-control-button.slider-left",
                  // prevEl: navigationPrevRef.current,
                  // nextEl: navigationNextRef.current,
                }}
                pagination={{
                  el: ".upcoming-events-lines-wrapper.mt-7.col-10",
                  // el: myPagination.current,
                  clickable: true,
                  dynamicMainBullets: 3,
                }}
                breakpoints={{
                  // when window width is >= 640px
                  300: {
                    width: 500,
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  975: {
                    width: 975,
                    slidesPerView: 2,
                    spaceBetween: 50,
                  },
                  // when window width is >= 768px
                  1400: {
                    width: 1400,
                    slidesPerView: 3,
                    spaceBetween: 180,
                  },
                }}
              >
                {articleGroups &&
                  articleGroups.map((item, index) => {
                    return (
                      <div key={index}>
                        {item.articlesNumber !== 0 && item.isVisible ? (
                          <SwiperSlide key={item.id}>
                            <div className="hand-book-slider-cart">
                              <div className="hand-book-slider-cart-1">
                                <img
                                  alt={item.name}
                                  className="hand-book-image"
                                  src={item.resolvedImageUrl}
                                />
                              </div>
                              <div
                                onClick={() => openNavUserSelect(item.isPublic)}
                                className="hand-book-slider-cart-info-wrapper"
                              >
                                <div
                                  className={`hand-book-slider-cart-info-wrapper  ${isPublicCard(
                                    item.isPublic
                                  )}`}
                                >
                                  <div className="hand-book-slider-cart-info-title">
                                    {item.name}
                                  </div>
                                  <div className="hand-book-slider-cart-info-count">
                                    {item.articlesNumber}{" "}
                                    {changeSelectedLetters(item)}{" "}
                                  </div>
                                  {resolveArticlePublicity(item)}
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        ) : null}
                      </div>
                    )
                  })}
              </Swiper>
              <div className="row d-flex align-items-center justify-content-center">
                <div
                  ref={myPagination}
                  className="upcoming-events-lines-wrapper mt-7 col-10"
                ></div>
                <div className="handbooks-navigation d-flex justify-content-center gap-4 mt-6 col col-lg-2">
                  <div
                    ref={navigationPrevRef}
                    className="hand-book-slider-control-button slider-left"
                  />
                  <div
                    ref={navigationNextRef}
                    className="hand-book-slider-control-button slider-right"
                  />
                </div>
              </div>
            </div>
            {/* <div className="hand-book-slider-control">
              <div className="hand-book-slider-line"></div>
              <div className="hand-book-slider-controls"></div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}
export default IndexHandbooksSection
