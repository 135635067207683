import React from "react"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"

import ArrowRightIcon from "../svg/arrow-right.inline.svg"

const IndexCardsSection = props => {
  const articles = props.articles || []
  const { i18n } = useTranslation()
  const lang = i18n.language


  return (
    <>
      <div className="cart-wrapper container">
        <div className="row d-flex justify-content-center">
          {articles[0] != null && (
            <div className="col-md-7 col-lg-4">
              <Link
                key={articles[0]?.id}
                to={`/${lang}/article/${articles[0].resolvedPath}`}
              >
                <div className="cart first-cart">
                  <div className="cart-title">{articles[0]?.title}</div>
                  <div className="cart-body">{articles[0]?.description}</div>
                  <ArrowRightIcon className="arrow_right mY-45 mX-40" />
                </div>
              </Link>
            </div>
          )}

          {articles[1] != null && (
            <div className="col-md-7 col-lg-4">
              <Link
                key={articles[1]?.id}
                to={`/${lang}/article/${articles[1].resolvedPath}`}
              >
                <div className="cart second-cart">
                  <div className="cart-title cart-title-dart">
                    {articles[1]?.title}
                  </div>
                  <div className="cart-body">{articles[1]?.description}</div>
                  <ArrowRightIcon className="arrow_right mY-45 mX-40" />
                </div>
              </Link>
            </div>
          )}

          {articles[2] != null && (
            <div className=" col-md-7 col-lg-4">
              <Link
                key={articles[2]?.id}
                to={`/${lang}/article/${articles[2].resolvedPath}`}
              >
                <div className="cart third-cart ">
                  <div className="cart-title cart-title-dart">
                    {articles[2]?.title}
                  </div>
                  <div className="cart-body">{articles[2]?.description}</div>
                  <ArrowRightIcon className="arrow_right mY-45 mX-40" />
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default IndexCardsSection
