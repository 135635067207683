import React, { useEffect, useState } from "react"

import IndexHeaderSection from "../sections/IndexHeaderSection"
import IndexCardsSection from "../sections/IndexCardsSection"
import IndexSuggestedSection from "../sections/IndexSuggestedSection"
import IndexHandbooksSection from "../sections/IndexHandbooksSection"
import IndexUpcomingEventsSection from "../sections/IndexUpcomingEventsSection"
import IndexGlossarySection from "../sections/IndexGlossarySection"
import IndexFooterSection from "../sections/IndexFooterSection"

import UserSideNavSection from "../sections/login_signup_side_navs/UserSideNavSection"
import SchoolSideNavSection from "../sections/login_signup_side_navs/SchoolSideNavSection"
import Layout from "../components/Layout"

import axios from "../http/axios"
import { useTranslation } from "react-i18next"

const IndexPage = props => {
  const { t, i18n } = useTranslation()

  const [arrayTypes, setArrayTypes] = useState([])
  const [arrayPrograms, setArrayPrograms] = useState([])
  const [arrayLocations, setArrayLocations] = useState([])
  const [arrayTransportStation, setArrayTransportStation] = useState([])
  const [arrayRegions, setArrayRegions] = useState([])

  const [arraySummerTypes, setArraySummerTypes] = useState([])
  const [arraySummerPrograms, setArraySummerPrograms] = useState([])
  const [arraySummerLocations, setArraySummerLocations] = useState([])

  useEffect(async () => {
    await axios
      .get(
        `/lookups/schoolOrganisationTypes/lookupresult?lang=${i18n.language}`
      )
      .then(res => {
        setArraySummerTypes(res.data)
      })
      .catch()
    await axios
      .get(
        `/lookups/summerSchoolEducationalPrograms/lookupresult?lang=${i18n.language}`
      )
      .then(res => {
        setArraySummerPrograms(res.data)
      })
      .catch()
    await axios
      .get(`/lookups/schoolLocationTypes/lookupresult?lang=${i18n.language}`)
      .then(res => {
        setArraySummerLocations(res.data)
      })
      .catch()
    await axios
      .get(`/lookups/schoolTypes/lookupresult?lang=${i18n.language}`)
      .then(res => {
        setArrayTypes(res.data)
      })
      .catch()
    await axios
      .get(`/educational-programs/lookup?lang=${i18n.language}`)
      .then(res => {
        setArrayPrograms(res.data)
      })
      .catch()
    await axios
      .get(`/lookups/settlementTypes/lookupresult?lang=${i18n.language}`)
      .then(res => {
        setArrayLocations(res.data)
      })
      .catch()
    await axios
      .get(`/transport-stations/lookupresult?lang=${i18n.language}`)
      .then(res => {
        setArrayTransportStation(res.data)
      })
      .catch()

    await axios
      .get(`/lookups/regions/lookupresult?lang=${i18n.language}`)
      .then(res => {
        setArrayRegions(res.data)
      })
      .catch()
  }, [])

  return (
    <Layout pageTitle="Smart Admissions Service" isSecondLayout={true}>
      <IndexHeaderSection
        arrayTypes={arrayTypes}
        arrayPrograms={arrayPrograms}
        arrayLocations={arrayLocations}
        arraySummerTypes={arraySummerTypes}
        arraySummerPrograms={arraySummerPrograms}
        arraySummerLocations={arraySummerLocations}
      />
      <IndexCardsSection articles={props.pageContext.articles} />
      <IndexSuggestedSection
        arrayTypes={arrayTypes}
        arrayPrograms={arrayPrograms}
        arrayTransportStation={arrayTransportStation}
        arrayRegions={arrayRegions}
      />
      <IndexHandbooksSection articleGroups={props.pageContext.articleGroups} />

      <IndexUpcomingEventsSection events={props.pageContext.events} />
      <IndexGlossarySection
        terms={props.pageContext.terms}
        termsEng={props.pageContext.termsEng}
        aboutItem={props.pageContext.aboutUs}
      />
      <IndexFooterSection aboutItem={props.pageContext.aboutUs} />

      <UserSideNavSection />
      <SchoolSideNavSection />
    </Layout>
  )
}

export default IndexPage
