import React from "react"

import { Link } from "gatsby"

import ArrowRightIcon from "../svg/arrow-right.inline.svg"

import ArrowRightIconRed from "../svg/arrow-right-red.inline.svg"

import { useTranslation } from "react-i18next"
import { openNavUser } from "../scripts/loginMenus"

import { Swiper, SwiperSlide } from "swiper/react"

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper"
// import "swiper/swiper.scss"
// import "swiper/components/navigation/navigation.scss"
// import "swiper/components/pagination/pagination.scss"
// import "swiper/components/scrollbar/scrollbar.scss"
import { useCurrentUser } from "../components/CurrentUserContext"

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

const IndexUpcomingEventsSection = props => {
  const items = props.events || []
  const { t, i18n } = useTranslation()
  const currentLang = i18n.language
  const { currentUser } = useCurrentUser()

  const eventsArray = items.filter((el, idx) => idx < 3)

 

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const monthNamesUa = [
    "Січня",
    "Лютого",
    "Березня",
    "Квітня",
    "Травня",
    "Червня",
    "Липня",
    "Серпня",
    "Вересня",
    "Жовтня",
    "Листопада",
    "Грудня",
  ]
  const monthNamesRu = [
    "Января",
    "Февраля",
    "Марта",
    "Апреля",
    "Мая",
    "Июня",
    "Июля",
    "Августа",
    "Сентября",
    "Октября",
    "Ноября",
    "Декабря",
  ]

  function changeMonth(item) {
    if (currentLang === "en") {
      return monthNames[new Date(item).getMonth()]
    }
    if (currentLang === "uk") {
      return monthNamesUa[new Date(item).getMonth()]
    }
    if (currentLang === "ru") {
      return monthNamesRu[new Date(item).getMonth()]
    }
  }

  function startDateDatesWithoutMonth(item) {
    return new Date(item).getDate()
  }

  function startDateDates(item) {
    return new Date(item).getDate() + " " + changeMonth(item)
  }

  const getEventMonth = item => {
    return changeMonth(item)
  }

  function endDateDates(item) {
    return (
      new Date(item).getDate() +
      " " +
      changeMonth(item) +
      " " +
      new Date(item).getFullYear()
    )
  }
  const openNavSection = () => {
    if (!currentUser) {
      openNavUser()
    }
  }

  const resolveEventDates = event => {
    if (
      getEventMonth(event?.startTimeUtc) !== getEventMonth(event?.endTimeUtc)
    ) {
      return (
        <div className="upcoming-events-cart-data">
          {startDateDates(event?.startTimeUtc)}–
          {endDateDates(event?.endTimeUtc)}
          <div>{event?.eventTypes}</div>
          <div>{event?.eventTypeResolved}</div>
        </div>
      )
    } else {
      return (
        <div className="upcoming-events-cart-data">
          {startDateDatesWithoutMonth(event?.startTimeUtc)}–
          {endDateDates(event?.endTimeUtc)}
          <div>{event?.eventTypes}</div>
          <div>{event?.eventTypeResolved}</div>
        </div>
      )
    }
  }

  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  const myPagination = React.useRef(null)

  return (
    <>
      <div id="upcomingEvent" className="upcoming-events">
        <div className="hand-book-slider-event container">
          <div className="upcoming-events-wrapper">
            {/* UPCOMING HEADER */}

            {!!items?.length &&
              <div className="row my-6">
                <div className="f-jb f-ac col-12">
                  <div className="upcoming-events-header-title">
                    {t("main.upcomingEvents.upcoming")}
                  </div>
                  {items.length > 1 && (
                    <Link to={`/${i18n.language}/events`}>
                      <div className="upcoming-events-header-view">
                        {t("main.upcomingEvents.viewAll")}
                      </div>
                    </Link>
                  )}
                </div>
              </div>
            }

            {!!items?.length && <div className="row">
              {items.length > 1 ? (
                <>
                  <Swiper
                    grabCursor={true}
                    slidesPerView={1}
                    spaceBetween={30}
                    className="mySwiper col-12"
                    navigation={{
                      nextEl:
                        ".hand-book-slider-control-button.slider-right-events",
                      prevEl:
                        ".hand-book-slider-control-button.slider-left-events",
                    }}
                    pagination={{
                      // el: ".upcoming-events-lines-wrapper",
                      // el: myPagination.current,
                      el:
                        ".events-pagination.upcoming-events-lines-wrapper.col-12.d-flex.justify-content-center.gap-4.mt-6",
                      clickable: true,
                      dynamicMainBullets: 3,
                    }}
                    // pagination={{
                    //   el: ".upcoming-events-lines-wrapper",
                    //   clickable: true,
                    //   dynamicMainBullets: 3,
                    // }}
                  >
                    <div className="upcoming-events-lines-wrapper"></div>

                    {eventsArray.length !== 0 &&
                      eventsArray.map(el => {
                        return (
                          <SwiperSlide key={el.id}>
                            <Link
                              to={
                                `/${currentLang}/event/${el?.resolvedPath}` ||
                                "/"
                              }
                            >
                              <div className="upcoming-events-cart-wrapper d-flex row align-items-center justify-content-center">
                                {/* <img
                                src={el?.resolvedImageUrl || ""}
                                alt={el.title}
                                className="upcoming-events-cart-image"
                              /> */}

                                <img
                                  src={el?.resolvedImageUrl || ""}
                                  alt={el.title}
                                  className="upcoming-events-cart-image col-lg-5"
                                />

                                <div className="col event-white-area-wrapper">
                                  <div className="upcoming-events-cart-text-wrapper py-6 ps-7 pe-6">
                                    <div className="upcoming-events-date-title-wrapper">
                                      {getEventMonth(el.startTimeUtc) !==
                                      getEventMonth(el.endTimeUtc) ? (
                                        <div className="upcoming-events-cart-data">
                                          {startDateDates(el.startTimeUtc)}–
                                          {endDateDates(el.endTimeUtc)}
                                          <div>{el.eventTypes}</div>
                                          <div>{el.eventTypeResolved}</div>
                                        </div>
                                      ) : (
                                        <div className="upcoming-events-cart-data">
                                          {startDateDatesWithoutMonth(
                                            el.startTimeUtc
                                          )}
                                          –{endDateDates(el.endTimeUtc)}
                                          <div>{el.eventTypes}</div>
                                          <div>{el.eventTypeResolved}</div>
                                        </div>
                                      )}

                                      <div className="upcoming-events-cart-title">
                                        {el.title}
                                      </div>
                                    </div>
                                    <div className="f-js curs-P mt-7">
                                      <div className="create-account-cart-info-link">
                                        {t("main.upcomingEvents.details")}
                                      </div>
                                      <ArrowRightIconRed className="arrow_right-red" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </SwiperSlide>
                        )
                      })}
                  </Swiper>
                  <div
                    ref={myPagination}
                    className="events-pagination upcoming-events-lines-wrapper col-12 d-flex justify-content-center gap-4 mt-6"
                  ></div>
                </>
              ) : (
                <Link
                  to={`/${currentLang}/event/${items[0]?.resolvedPath}` || "/"}
                >
                  <div className="upcoming-events-cart-wrapper d-flex row align-items-center justify-content-center">
                    <img
                      src={items[0]?.resolvedImageUrl || ""}
                      alt={items[0]?.title}
                      className="upcoming-events-cart-image col-lg-5"
                    />

                    <div className="col event-white-area-wrapper">
                      <div className="upcoming-events-cart-text-wrapper py-6 ps-7 pe-6">
                        <div className="upcoming-events-date-title-wrapper">
                          {resolveEventDates(items[0])}

                          <div className="upcoming-events-cart-title">
                            {items[0]?.title}
                          </div>
                        </div>
                        <div className="f-js curs-P mt-7">
                          <div className="create-account-cart-info-link">
                            {t("main.upcomingEvents.details")}
                          </div>
                          <ArrowRightIconRed className="arrow_right-red" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              )}
            </div>}
            {/* UPCOMING BUTTON CART */}
            <div
              id="compareSchools"
              className="upcoming-events-carts-button row d-flex justify-content-center"
            >
              <Link
                to={
                  (currentUser && `/${currentLang}/profile/compare-schools`) ||
                  `/${currentLang}/`
                }
                className="col-lg-7 mb-5"
              >
                <div
                  onClick={openNavSection}
                  className="upcoming-events-cart-button-first p-5 "
                >
                  <div className="cart-title">
                    {t("main.upcomingEvents.choose")}
                  </div>
                  <div>
                    <div className="cart-body">
                      {t("main.upcomingEvents.criteria")}
                    </div>
                    <ArrowRightIcon className="arrow_right mB-35 mT-45 mX-40" />
                  </div>
                </div>
              </Link>

              <Link
                to={`/${currentLang}/ask-consultants` || "/"}
                className="col-lg-5 mb-5"
              >
                <div className="upcoming-events-cart-button-second p-5">
                  <div className="cart-title">
                    {t("main.upcomingEvents.help")}
                  </div>
                  <div className="cart-body">
                    {t("main.upcomingEvents.consultants")}
                  </div>
                  <ArrowRightIcon className="arrow_right mY-45 mX-40" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div id="our-cons"></div>
    </>
  )
}

export default IndexUpcomingEventsSection
