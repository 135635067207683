import React, { useEffect, useState } from "react"

import ArrowRightIconRed from "../svg/arrow-right-red.inline.svg"

import { useTranslation } from "react-i18next"

import { navigate } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

const IndexSuggestedSection = props => {
  const { i18n, t } = useTranslation()

  const navigateToSpecificSearchResults = (
    schoolType,
    educationalProgram,
    region,
    transportStations,
    internationalStudentsPercentMin,
    internationalStudentsPercentMax,
    studentsAmount
  ) => {
    navigate(`/${
      i18n.language
    }/result-map-page?schoolTypes=${encodeURIComponent(schoolType)}
      		&educationalProgrammes=${encodeURIComponent(educationalProgram)}
			&regions=${encodeURIComponent(region)}
			&transportStations=${encodeURIComponent(transportStations)}
			&internationalStudentsPercentMin=${encodeURIComponent(
        internationalStudentsPercentMin
      )}
			&internationalStudentsPercentMax=${encodeURIComponent(
        internationalStudentsPercentMax
      )}
			&studentsAmountMin=${encodeURIComponent(studentsAmount)}
      		`)
  }

  const [arrayTypes, setArrayTypes] = useState([])
  const [arrayTransportStation, setArrayTransportStation] = useState([])
  const [arrayRegions, setArrayRegions] = useState([])
  const [arrayPrograms, setArrayPrograms] = useState([])

  useEffect(() => {
    setArrayTypes(props.arrayTypes)
    setArrayTransportStation(props.arrayTransportStation)
    setArrayRegions(props.arrayRegions)
    setArrayPrograms(props.arrayPrograms)
  }, [props])

  let boysSchools = []

  for (let boy of arrayTypes) {
    if (
      boy.value.includes("boys") ||
      boy.value.includes("мальчиков") ||
      boy.value.includes("хлопців")
    ) {
      boysSchools.push(boy.value)
    }
  }

  let londonStations = []
  for (let london of arrayTransportStation) {
    if (london.value.includes("London") || london.value.includes("Лондон")) {
      londonStations.push(london.value)
    }
  }

  let scotlandRegion = []

  for (let region of arrayRegions) {
    if (
      region.value.includes("Scotland") ||
      region.value.includes("Шотландия") ||
      region.value.includes("Шотландія")
    ) {
      scotlandRegion.push(region.value)
    }
  }

  let ibProgram = []

  for (let program of arrayPrograms) {
    if (program.value.includes("IB")) {
      ibProgram.push(program.value)
    }
  }

  return (
    <>
      <div id="schoolsDirectory" className="suggested-wrapper container mt-8">
        <div className="row">
          {/* SUGGESTED TITLE */}
          <div className="suggested-title mb-7 col-12">
            {t("main.suggested.searches")}
          </div>
        </div>
        {/* SUGGESTED CART LIST */}
        <div className="suggested-cart-list row d-flex gap-8 justify-content-between">
          <div
            className="col-lg-5 position-relative curs-P"
            onClick={() => {
              navigateToSpecificSearchResults(
                "",
                "",
                "",
                londonStations,
                "",
                ""
              )
            }}
          >
            <StaticImage
              src="../images/suggested-cart-bg-1.jpg"
              formats={["AUTO", "WEBP"]}
              width={560}
              height={400}
              className="suggested-cart suggested-cart-1"
              alt=""
            />
            <div className="suggested-cart-info">
              <div className="suggested-cart-info-title">
                {t("main.suggested.nearLondon")}
              </div>
              <div className="suggested-cart-info-count"></div>
              <ArrowRightIconRed className="arrow_right-red" />
            </div>
          </div>
          <div
            className="col-lg-5 position-relative curs-P suggested-card-right-margin"
            onClick={() => {
              navigateToSpecificSearchResults("", ibProgram, "", "", "", "")
            }}
          >
            <StaticImage
              src="../images/ib_programs.jpg"
              formats={["AUTO", "WEBP"]}
              width={560}
              height={400}
              className="suggested-cart suggested-cart-2"
              alt=""
            />
            <div className="suggested-cart-info">
              <div className="suggested-cart-info-title">
                {t("main.suggested.ibProg")}
              </div>
              <div className="suggested-cart-info-count"></div>
              <ArrowRightIconRed className="arrow_right-red" />
            </div>
          </div>
          <div
            className="col-lg-5 position-relative curs-P"
            onClick={() => {
              navigateToSpecificSearchResults("", "", "", "", "", "", 1000)
            }}
          >
            <StaticImage
              src="../images/quack_schools.jpg"
              formats={["AUTO", "WEBP"]}
              width={560}
              height={400}
              className="suggested-cart suggested-cart-5"
              alt=""
            />
            <div className="suggested-cart-info">
              <div className="suggested-cart-info-title">
                {t("main.suggested.largeSchools")}
              </div>
              <div className="suggested-cart-info-count"></div>
              <ArrowRightIconRed className="arrow_right-red" />
            </div>
          </div>
          <div
            className="col-lg-5 position-relative curs-P suggested-card-right-margin"
            onClick={() => {
              navigateToSpecificSearchResults(
                "",
                "",
                scotlandRegion,
                "",
                "",
                ""
              )
            }}
          >
            <StaticImage
              src="../images/scottish_schools.jpg"
              formats={["AUTO", "WEBP"]}
              width={560}
              height={400}
              className="suggested-cart suggested-cart-6"
              alt=""
            />
            <div className="suggested-cart-info">
              <div className="suggested-cart-info-title">
                {t("main.suggested.scottishSchools")}
              </div>
              <div className="suggested-cart-info-count"></div>
              <ArrowRightIconRed className="arrow_right-red" />
            </div>
          </div>
        </div>
        {/* SUGGESTED BOTTOM */}
        <div className="show-more-suggestions-button row my-9">
          <div
            className="f-jc col-12"
            onClick={() => {
              navigateToSpecificSearchResults("", "", "", "", "", "")
            }}
          >
            <div className="show-more-button">
              <div>{t("main.suggested.showMore")}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default IndexSuggestedSection
