import React from "react"
import ArrowRightIconRed from "../svg/arrow-right-red.inline.svg"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"
import Copyright from "../components/copyright"

const IndexFooterSection = props => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const aboutItem = props.aboutItem || {}

  return (
    <div id="aboutUs" className="bottom-block d-flex align-items-end pb-5">
      {/* ABOUT US */}
      {/* <div className="about-us-wrapper">
        <div className="about-us-info">
          <div className="about-us-info-title">{aboutItem.title}</div>
          <div className="about-us-info-description">{aboutItem.description}
          </div>
          <Link to={`/${lang}/about/`}>
            <div className="f-js curs-P">
              <div className="create-account-cart-info-link">{t("main.footer.learn")}</div>
              <ArrowRightIconRed className="arrow_right-red" />
            </div>
          </Link>
        </div>
        <div className="about-us-image">
        </div>
      </div> */}
      {/* BOTTOM INFO */}
      <div className="bottom-info-wrapper container">
        <div className="row">
          <div className="bottom-info-left-block col-lg-6 d-flex gap-3">
            <Copyright />
            <div className="">·</div>

            <Link to={`/${lang}/privacy`}>
              <div className="bottom-link">{t("school.footer.privacy")}</div>
            </Link>
            <div className="">·</div>
            <Link to={`/${lang}/terms`}>
              <div className="bottom-link terms">
                {t("school.footer.terms")}
              </div>
            </Link>
          </div>
          <div className="bottom-info-right-block col-lg-6 d-flex gap-3 justify-content-end">
            <a target="_blank" href="https://www.facebook.com/businesslinkkyiv">
              <div className="bottom-link">Facebook</div>
            </a>
            <div className="">·</div>
            <a
              target="_blank"
              href="https://www.instagram.com/businesslink.com.ua/"
            >
              <div className="bottom-link">Instagram</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IndexFooterSection
