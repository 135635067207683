import React, { useEffect, useState } from "react"
import ArrowRightIconRed from "../svg/arrow-right-red.inline.svg"

import { useTranslation } from "react-i18next"
import GlossarySideNavSection from "./GlossarySideNavSection"
import { StaticImage } from "gatsby-plugin-image"

import { Link } from "gatsby"

const IndexGlossarySection = props => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language

  const aboutItem = props.aboutItem || {}
  const [termsArray, setTermsArray] = useState([])

  // Terms Cyrillic

  const [allLattersKr, setAllLattersKr] = useState([])
  const [selectlatterKr, setSelectlatterKr] = useState()
  const [filterArrayKr, setFilterArrayKr] = useState([])
  const [selectItemKr, setSelectItemKr] = useState()

  // Terms Latin

  const [allLattersLt, setAllLattersLt] = useState([])
  const [selectlatterLt, setSelectlatterLt] = useState()
  const [filterArrayLt, setFilterArrayLt] = useState([])
  const [selectItemLt, setSelectItemLt] = useState()

  useEffect(async () => {
    sortSelectLatter(props.terms)
    setTermsArray(props.terms)
  }, [])

  const sortSelectLatter = array => {
    const arraySelectKr = []
    const arraySelectLt = []
    array.forEach(item => {
      const latterSelect = item.name?.substr(0, 1).toUpperCase()
      if (
        !arraySelectKr.includes(latterSelect) &&
        latterSelect?.charCodeAt(0) > 91
      ) {
        arraySelectKr.push(latterSelect)
      } else if (
        !arraySelectLt.includes(latterSelect) &&
        latterSelect?.charCodeAt(0) < 91
      ) {
        arraySelectLt.push(latterSelect)
      }
    })
    const sortArraySelectKr = [...arraySelectKr].sort((a, b) =>
      a.localeCompare(b)
    )
    setAllLattersKr(sortArraySelectKr)
    showContentLettersKr(sortArraySelectKr[0], array)

    const sortArraySelectLt = [...arraySelectLt].sort((a, b) =>
      a.localeCompare(b)
    )
    setAllLattersLt(sortArraySelectLt)
    showContentLettersLt(sortArraySelectLt[0], array)
  }

  const showContentLettersKr = (letters, termsArraySelect) => {
    const arrayName = []
    termsArraySelect.forEach(item => {
      const filterForName = items =>
        items?.name
          ? items?.name.substr(0, 1).toUpperCase().includes(letters)
          : null
      if (filterForName(item)) {
        arrayName.push(item)
      }
    })
    if (arrayName.length && props.terms) {
      setSelectlatterKr(letters)
      const sortArrayKr = arrayName.sort((a, b) => a.name.localeCompare(b.name))
      setFilterArrayKr(sortArrayKr)

      setSelectlatterLt()
      setFilterArrayLt()
    }
  }

  const showContentLettersLt = (letters, termsArraySelect) => {
    const arrayName = []
    termsArraySelect.forEach(itemSelect => {
      const filterForNameSelect = items =>
        items?.name
          ? items?.name.substr(0, 1).toUpperCase().includes(letters)
          : null
      if (filterForNameSelect(itemSelect)) {
        arrayName.push(itemSelect)
      }
    })
    if (arrayName.length && props.terms) {
      setSelectlatterLt(letters)
      const sortArrayLt = arrayName.sort((a, b) => a.name.localeCompare(b.name))
      setFilterArrayLt(sortArrayLt)

      setSelectlatterKr()
      setFilterArrayKr()
    }
  }

  return (
    <div id="glossary" className="container mt-13">
      <div className="glossary-body row">
        {/* GLOSSARY HEADER */}

        <div className="glossary-header col-12">{t("main.glossary.title")}</div>

        {/* GLOSSARY LETTERS Latin */}
        <div className="row">
          <div className="glossary-letters col-12 mt-5">
            {allLattersLt.map(item => {
              return (
                <div
                  key={item}
                  onClick={() => showContentLettersLt(item, termsArray)}
                  className={`glossary-letter ${
                    selectlatterLt === item ? "active" : ""
                  }`}
                >
                  {item}
                </div>
              )
            })}
          </div>
        </div>
        {/* GLOSSARY LETTERS Cyrillic */}
        <div className="row">
          <div className="glossary-letters col-12 mt-2">
            {allLattersKr.map(item => {
              return (
                <div
                  key={item}
                  onClick={() => showContentLettersKr(item, termsArray)}
                  className={`glossary-letter ${
                    selectlatterKr === item ? "active" : ""
                  }`}
                >
                  {item}
                </div>
              )
            })}
          </div>
        </div>
        {/* <div className="row"> */}
        {/* GLOSSARY BUTTONS Latin */}
        {filterArrayLt ? (
          <div className="glossary-buttons row mt-6">
            {filterArrayLt.map(item => {
              return (
                <div key={item.id} className="col-md-6 col-lg-3 my-3">
                  <div
                    onClick={() => {
                      setSelectItemLt(item)
                      setSelectItemKr()
                    }}
                    className="glossary-button px-4 py-3"
                  >
                    {item?.name}
                  </div>
                </div>
              )
            })}
          </div>
        ) : null}
        {/* </div> */}
        {selectItemLt && (
          <GlossarySideNavSection
            props={selectItemLt}
            changeItem={setSelectItemLt}
          />
        )}

        {/* GLOSSARY BUTTONS Cyrillic*/}
        {filterArrayKr && (
          <div className="glossary-buttons row mt-6">
            {filterArrayKr
              ? filterArrayKr.map(item => {
                  return (
                    <div key={item.id} className="col-md-6 col-lg-3 my-3">
                      <div
                        onClick={() => {
                          setSelectItemKr(item)
                          setSelectItemLt()
                        }}
                        className="glossary-button px-4 py-3"
                      >
                        {item?.name}
                      </div>
                    </div>
                  )
                })
              : null}
          </div>
        )}

        {selectItemKr && (
          <GlossarySideNavSection
            props={selectItemKr}
            changeItem={setSelectItemKr}
          />
        )}
        <div className="d-flex justify-content-center">
          <div className="about-us-wrapper d-flex col-12">
            <div className="about-us-info p-5">
              <div className="">
                <div className="about-us-info-title mb-3">
                  {aboutItem.title}
                </div>
                {/* <div className="about-us-info-title mb-3">Some title of mine</div> */}
                <div className="about-us-info-description">
                  {aboutItem.description}
                </div>
              </div>
              {/* <div className="about-us-info-description">
               Business Link is one of the largest educational advisory companies operating  in Ukraine, with strong international links, concentrating on the UK only. We have around 500 students annually, both children and adults, going for short and long term programmes to the UK, having English courses, studying  at boarding schools or universities.

              </div> */}
              <Link to={`/${lang}/about/`} className="mt-6">
                <div className="f-js curs-P">
                  <div className="create-account-cart-info-link">
                    {t("main.footer.learn")}
                  </div>
                  <ArrowRightIconRed className="arrow_right-red" />
                </div>
              </Link>
            </div>
            {/* <div className="about-us-image"></div> */}
            <StaticImage
              src="../images/about-us.jpg"
              formats={["AUTO", "WEBP"]}
              width={480}
              height={560}
              className="about-us-image"
              alt=""
            />
          </div>
        </div>
      </div>
      {/* <div className="about-us-wrapper">
        <div className="about-us-info">
          <div className="about-us-info-title">{aboutItem.title}</div>
          <div className="about-us-info-description">
            {aboutItem.description}
          </div>
          <Link to={`/${lang}/about/`}>
            <div className="f-js curs-P">
              <div className="create-account-cart-info-link">
                {t("main.footer.learn")}
              </div>
              <ArrowRightIconRed className="arrow_right-red" />
            </div>
          </Link>
        </div>
        <div className="about-us-image"></div>
      </div> */}
    </div>
  )
}

export default IndexGlossarySection
