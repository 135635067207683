import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

import SearchIcon from "../../svg/search.inline.svg"

import { useTranslation } from "react-i18next"
import { SCHOOL_MODES } from "../../constants/schoolModes"

const IndexSearchSection = props => {
  const { t, i18n } = useTranslation()
  const currentLang = i18n.language

  const [schoolMode, setSchoolMode] = useState(SCHOOL_MODES.SCHOOL)

  const [arrayTypes, setArrayTypes] = useState(props.arrayTypes)

  const [searchByNameValue, setSearchByNameValue] = useState([])

  const [allSelectTypes, setAllSelectTypes] = useState({
    schoolTypes: [],
    educationalProgrammes: [],
    locations: [],
    searchInput: [],
  })

  const [allSelectTypesSummer, setAllSelectTypesSummer] = useState({
    schoolOrganisationTypes: [],
    educationalProgrammes: [],
    locationTypes: [],
    searchInput: [],
  })

  const [arrayPrograms, setArrayPrograms] = useState(props.arrayPrograms)
  const [arrayLocations, setArrayLocations] = useState(props.arrayLocations)

  const [selectTypes, setSelectTypes] = useState()
  const [selectPrograms, setSelectPrograms] = useState()
  const [selectLocations, setSelectLocations] = useState()

  useEffect(() => {
    setArrayTypes(
      schoolMode === SCHOOL_MODES.SCHOOL
        ? props.arrayTypes
        : props.arraySummerTypes
    )
    setArrayPrograms(
      schoolMode === SCHOOL_MODES.SCHOOL
        ? props.arrayPrograms
        : props.arraySummerPrograms
    )
    setArrayLocations(
      schoolMode === SCHOOL_MODES.SCHOOL
        ? props.arrayLocations
        : props.arraySummerLocations
    )
  }, [schoolMode])

  const handleSearchByNameChange = e => {
    const { name, value } = e.target
    setSearchByNameValue(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleChange = e => {
    const { name, value } = e.target

    if (e.target.value === t("main.header.types")) {
      setSelectTypes(e.target.value)
    } else if (e.target.value === t("main.header.programmes")) {
      setSelectPrograms(e.target.value)
    } else if (e.target.value === t("main.header.locations")) {
      setSelectLocations(e.target.value)
    } else {
      if (schoolMode === SCHOOL_MODES.SUMMER_SCHOOL) {
        setAllSelectTypesSummer(prevState => ({
          ...prevState,
          [name]: [value],
        }))
      }
      setAllSelectTypes(prevState => ({
        ...prevState,
        [name]: [value],
      }))
    }
  }

  function getSchoolTypesQueryParam() {
    return allSelectTypes.schoolTypes[0] !== undefined &&
      allSelectTypes.schoolTypes[0] !== ""
      ? `&schoolTypes=${encodeURIComponent(allSelectTypes.schoolTypes[0])}`
      : ""
  }

  function getEducationalProgramsQueryParam() {
    return allSelectTypes.educationalProgrammes[0] !== undefined &&
      allSelectTypes.educationalProgrammes[0] !== ""
      ? `&educationalProgrammes=${encodeURIComponent(
          allSelectTypes.educationalProgrammes[0]
        )}`
      : ""
  }

  function getSettlementTypesQueryParam() {
    return allSelectTypes.locations[0] !== undefined &&
      allSelectTypes.locations[0] !== ""
      ? `&settlementTypes=${encodeURIComponent(allSelectTypes.locations[0])}`
      : ""
  }

  function getSummerEducationalProgramsQueryParam() {
    return allSelectTypesSummer.educationalProgrammes[0] !== undefined &&
      allSelectTypesSummer.educationalProgrammes[0] !== ""
      ? `&educationalProgrammes=${encodeURIComponent(
          allSelectTypesSummer.educationalProgrammes[0]
        )}`
      : ""
  }

  function getSchoolOrganisationTypesQueryParam() {
    return allSelectTypesSummer.schoolOrganisationTypes[0] !== undefined &&
      allSelectTypesSummer.schoolOrganisationTypes[0] !== ""
      ? `&schoolOrganisationTypes=${encodeURIComponent(
          allSelectTypesSummer.schoolOrganisationTypes[0]
        )}`
      : ""
  }

  function getSearchNameQueryParam(schoolNameValue) {
    return schoolNameValue !== "" ? ` &searchInput=${schoolNameValue}` : ""
  }

  function handleFormSubmit(e) {
    let schoolNameValue
    if (searchByNameValue.searchInput === undefined) {
      schoolNameValue = ""
    } else {
      schoolNameValue = searchByNameValue.searchInput.toString()
    }

    e.preventDefault()

    localStorage.setItem("search-school-mode", schoolMode)
    if (schoolMode === SCHOOL_MODES.SUMMER_SCHOOL) {
      navigate(`/${
        i18n.language
      }/result-map-page?schoolMode=${encodeURIComponent(schoolMode)}
      ${getSummerEducationalProgramsQueryParam()}
      ${getSchoolOrganisationTypesQueryParam()}
      ${getSearchNameQueryParam(schoolNameValue)}`)
    } else {
      navigate(`/${
        i18n.language
      }/result-map-page?schoolMode=${encodeURIComponent(schoolMode)}
        ${getSchoolTypesQueryParam()} 
        ${getEducationalProgramsQueryParam()} 
        ${getSettlementTypesQueryParam()} 
        ${getSearchNameQueryParam(schoolNameValue)}`)
    }
  }

  const [nameSearchPlaceholder, setNameSearchPlaceholder] = useState()
  useEffect(() => {
    if (currentLang === "en") {
      setNameSearchPlaceholder("Start your search")
    } else if (currentLang === "ru") {
      setNameSearchPlaceholder("Начните свой поиск")
    } else {
      setNameSearchPlaceholder("Почніть свій пошук")
    }
  })

  return (
    <div id="search-block" className="search-block container mt-4">
      {/* SEARCH INPUT */}
      <div className="col-12">
        <form onSubmit={handleFormSubmit} className=" row">
          <div
            id="search_panel"
            className="search-select-wrapper search-input-wrapper col-lg-4"
          >
            <SearchIcon className="search-icon" />

            <input
              name="searchInput"
              onChange={handleSearchByNameChange}
              placeholder={nameSearchPlaceholder}
              id="searchList2"
              className="search-input"
              type="text"
              list="arr"
            />
            <select
              name="selectSchools"
              onChange={e => {
                let changedSchoolMode =
                  e.target.value === "Summer schools" ||
                  e.target.value === "Летние школы" ||
                  e.target.value === "Літні школи"
                    ? SCHOOL_MODES.SUMMER_SCHOOL
                    : SCHOOL_MODES.SCHOOL
                setSchoolMode(changedSchoolMode)
              }}
              className="contactSelectName"
              value={
                schoolMode === SCHOOL_MODES.SCHOOL
                  ? t("main.header.schools")
                  : t("main.header.summerSchools")
              }
            >
              <option defaultValue="Schools">{t("main.header.schools")}</option>
              <option defaultValue="Summer schools">
                {t("main.header.summerSchools")}
              </option>
              )
            </select>
          </div>

          {/* SELECT TYPES */}
          <div className="search-select-wrapper col-6 col-sm-6 col-md-4 col-lg-2">
            <div id="select-types" className="custom-select-wrapper">
              <div className="custom-select">
                <div>
                  <select
                    id="typesId"
                    // name="schoolTypes"
                    name={
                      schoolMode === SCHOOL_MODES.SUMMER_SCHOOL
                        ? "schoolOrganisationTypes"
                        : "schoolTypes"
                    }
                    onChange={handleChange}
                    className="contactSelect"
                  >
                    <option>{t("main.header.types")}</option>
                    {arrayTypes.map(item => {
                      return (
                        <option key={item.id} defaultValue={item.value}>
                          {item.value}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {/* SELECT PROGRAM */}
          <div className="search-select-wrapper col-6 col-sm-6 col-md-4 col-lg-2">
            <div id="select-programmes" className="custom-select-wrapper">
              <div className="custom-select">
                <select
                  id="educId"
                  name="educationalProgrammes"
                  onChange={handleChange}
                  className="contactSelect"
                >
                  <option>{t("main.header.programmes")}</option>
                  {arrayPrograms.map(item => {
                    return (
                      <option key={item.id} name="Основной" defaultValue="">
                        {item.value}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          </div>
          {/* SELECT LOCATION */}
          <div className="search-select-wrapper col-sm-12 col-md-4 col-lg-2">
            <div id="select-location" className="custom-select-wrapper">
              <div className="custom-select">
                {schoolMode === SCHOOL_MODES.SCHOOL && (
                  <select
                    id="locationId"
                    // name="locations"
                    name={
                      schoolMode === SCHOOL_MODES.SUMMER_SCHOOL
                        ? "locationTypes"
                        : "locations"
                    }
                    onChange={handleChange}
                    className="contactSelect"
                  >
                    <option>{t("search.anyLocation")}</option>
                    {arrayLocations.map(item => {
                      return (
                        <option key={item.id} name="Basic">
                          {item.value}
                        </option>
                      )
                    })}
                  </select>
                )}
              </div>
            </div>
          </div>
          {/* SHOW ALL */}
          <button className="show-all-button col" type="submit">
            <div>{t("main.header.showAll")}</div>
            {/* <div className="show-all-button-count"></div> */}
          </button>
        </form>
      </div>
    </div>
  )
}

export default IndexSearchSection
